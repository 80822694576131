import { languages, languageObject } from 'types/languages';
import { type navItemObject } from './navItemObject';

export const LANGUAGES:Record<languages, languageObject> = {
    ENGLISH: {
        CODE: 'en',
        ISO: 'en-US',
        LABEL: 'ENGLISH 🇺🇸',
        FILE: 'en-US.ts',
        DIRECTION: 'ltr',
        DEFAULT: true
    },
    URDU: {
        CODE: 'ur',
        ISO: 'ur-PK',
        LABEL: 'URDU 🇵🇰',
        FILE: 'ur-URD.ts',
        DIRECTION: 'rtl',
        DEFAULT: false
    }
} as const;

export const STRATEGIES = {
    PREFIX: 'prefix',
    PREFIX_EXCEPT_DEFAULT: 'prefix_except_default',
    PREFIX_AND_DEFAULT: 'prefix_and_default',
    NO_PREFIX: 'no_prefix'
} as const;

export const FALLBACK_LOCALE = 'en' as const;
export const DEFAULT_COOKIE = 'i18n_cookie_locale' as const;

/* App Navigations constants */
export const navigations:Array<navItemObject> = [
    {
        name: 'Dashboard',
        href: '/',
        icon: 'home',
        current: false
    },
    {
        name: 'Issues',
        href: '/issues',
        icon: 'users',
        current: false
    }
    // {
    //     name: 'Users Management',
    //     href: '/usersadmin',
    //     icon: 'users-management',
    //     current: false,
    //     children: [{
    //         name: 'Users',
    //         href: '/admin/users',
    //         icon: 'users-group',
    //         current: false
    //     },
    //     {
    //         name: 'Roles',
    //         href: '/admin/roles',
    //         icon: 'users-roles',
    //         current: false
    //     },
    //     {
    //         name: 'Permissions',
    //         href: '/admin/permissions',
    //         icon: 'permissions',
    //         current: false
    //     },
    //     {
    //         name: 'User Invites',
    //         href: '/admin/userinvites',
    //         icon: 'user-invites',
    //         current: false
    //     }]

    // }

];

export const navigations1:Array<navItemObject> = [
    {
        name: 'Dashboard',
        href: '/',
        icon: 'home',
        current: false
    },
    {
        name: 'Issues',
        href: '/issues',
        icon: 'users',
        current: false
    },
    {
        name: 'Users Management',
        href: '/usersadmin',
        icon: 'users-group',
        current: false,
        navSubItemObject: [{
            name: 'Users',
            href: '/admin/users',
            icon: 'users-group',
            current: false
        },
        {
            name: 'Roles',
            href: '/admin/roles',
            icon: 'users-roles',
            current: false
        },
        {
            name: 'Permissions',
            href: '/admin/permissions',
            icon: 'permissions',
            current: false
        },
        {
            name: 'User Invites',
            href: '/admin/userinvites',
            icon: 'user-invites',
            current: false
        }]

    }

];
/* Breadcrumb constants */

export const issueDetail = {
    name: 'Detail Page'
};
export const issueForm = {
    name: 'Issue Form'
};

export const capture = {
    name: 'Capture'
};

export const adminUsersManagement = {
    name: 'Users Management'
};

export const adminUsers = {
    name: 'Users'
};

export const adminRoles = {
    name: 'Roles'
};

export const adminPermissions = {
    name: 'Permissions'
};

export const adminUserRoles = {
    name: 'User Roles'
};

export const adminUserInvites = {
    name: 'User Invites'
};
